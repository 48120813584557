.navbar_ {
    background-color: transparent !important;
    box-shadow: none !important;
    margin: 0px !important;
}

.navbar__icon {
    color: black;
    font-size: 3rem !important;
}


.navbar__item {
    color: black;
    line-height: 10px !important;
    cursor: pointer;
}

.navbar__mobile {
    background-color: transparent !important;
    box-shadow: none !important;
    display: flex;

}

.navbar__item_border {
    border-right: 3px solid black;
}



/* For Mobile Portrait View */
@media screen and (max-device-width: 480px) and (orientation: portrait) {
    .navbar__icon {
        color: black;
        font-size: 3rem !important;
    }


    .navbar {
        margin-left: 0px !important;
        /*  top: 10px !important; */
    }
}