
/* Default Design */
.gfg-div {

	/* To make all elements center */
	display: flex;
	justify-content: center;
	align-items: center;

	/* Default Styling */
	margin: 20px auto;
	padding: 30px;
	font-size: 30px;
	width: 300px;
	height: 300px;
	background-color: darkseagreen;
	color: black;
}



.main__name {
    font-style: italic;
    margin-bottom: 0.1rem !important;
    color:white;
    font-weight: 800;
}

.main__subname {
    font-style: italic;
    font-weight: 900 !important;
    font-size: 3rem !important;
    margin-bottom: 0.1rem !important;
}



/* For Desktop View */
@media screen and (min-width: 1024px) {
	.main {
        width: 100%;
        height: 100%;
        position: fixed;
        top: 25%;
        left: 0;
    }


    .main__name {
        padding: 0px !important;
        font-size: 14rem !important;
    }


    .main__subname {
        font-style: italic;
        font-weight: 900 !important;
        font-size: 3rem !important;
        margin-bottom: 0.1rem !important;
    }
    
    .main__button {
        background: #231A36 !important;
        /* Rectangle 27 */
        display: inline-block;
        color: white !important;
        border-radius: 40px !important;
        padding-top: 15px  !important;
        padding-bottom: 15px !important;
        padding-right: 50px  !important;
        padding-left: 50px  !important;
        font-size: 1.5rem !important;
        text-transform: none !important;

    }
}

/* For Tablet View */
@media screen and (min-device-width: 768px) 
	and (max-device-width: 1024px) {
        .main {
            width: 100%;
            height: 100%;
            position: fixed;
            top: 25%;
            left: 0;
        }
    
        .main__name {
            /*         display: flex;
            justify-content: center; */
            font-size: 5rem !important;
    
        }
    
        .main__subname {
            font-style: italic;
            font-weight: 900 !important;
            font-size: 2.5rem !important;
            /*   margin-bottom: 0.1rem !important; */
            line-height: 45px !important;
        }
    
    
        .main__button {
      /*       background: #231A36 !important;  */
            /* Rectangle 27 */
    
            background: #231A36 !important; 
    
            text-transform: none !important;
            color: white !important;
            border-radius: 40px !important;
    
            font-size: 1.2rem !important;
            width: 100%;
    
    
        }
}

/* For Mobile Portrait View */
@media screen and (max-device-width: 480px) 
	and (orientation: portrait) {
        .main {
            width: 100%;
            height: 100%;
            position: fixed;
            top: 0;
            left: 0;
            display: flex;
            overflow: auto;
        }
    
        .main__name {
            /*         display: flex;
            justify-content: center; */
            font-size: 5rem !important;
    
        }
    
        .main__subname {
            font-style: italic;
            font-weight: 900 !important;
            font-size: 2.5rem !important;
            /*   margin-bottom: 0.1rem !important; */
            line-height: 45px !important;
        }
    
    
        .main__button {
            background: #231A36 !important; 
            /* Rectangle 27 */
    
            text-transform: none !important;
            color: white !important;
            border-radius: 40px !important;
    
            font-size: 1.2rem !important;
            width: 100%;
    
    
        }
    
    .main__sub
    {
        margin: auto;
    }
}

/* For Mobile Landscape View */
@media screen and (max-device-width: 640px) 
	and (orientation: landscape) {
        .main {
            width: 100%;
            height: 100%;
            position: fixed;
    
            left: 0;
        }
    
        .main__name {
            /*         display: flex;
            justify-content: center; */
            font-size: 5rem !important;
    
        }
    
        .main__subname {
            font-style: italic;
            font-weight: 900 !important;
            font-size: 2.5rem !important;
            /*   margin-bottom: 0.1rem !important; */
            line-height: 45px !important;
        }
    
    
        .main__button {
      /*       background: #231A36 !important;  */
            /* Rectangle 27 */
    
            background: #231A36 !important; 
    
            text-transform: none !important;
            color: white !important;
            border-radius: 40px !important;
    
            font-size: 1.2rem !important;
            width: 100%;
    
    
        }
}

/* For Mobile Phones Portrait or Landscape View */
@media screen
	and (max-device-width: 640px) {
        .main {
            width: 100%;
            height: 100%;
            position: fixed;

            left: 0;
        }
    
        .main__name {
            /*         display: flex;
            justify-content: center; */
            font-size: 5rem !important;
    
        }
    
        .main__subname {
            font-style: italic;
            font-weight: 900 !important;
            font-size: 2.5rem !important;
            /*   margin-bottom: 0.1rem !important; */
            line-height: 45px !important;
        }
    
    
        .main__button {
      /*       background: #231A36 !important;  */
            /* Rectangle 27 */
    
            background: #231A36 !important; 
    
            text-transform: none !important;
            color: white !important;
            border-radius: 40px !important;
    
            font-size: 1.2rem !important;
            width: 100%;
    
    
        }
}

/* For iPhone 4 Portrait or Landscape View */
@media screen and (min-device-width: 320px) 
	and (-webkit-min-device-pixel-ratio: 2) {
	.gfg-div {
		width: 400px;
		height: 400px;
		background-color: brown;
		color: black;
	}
}

/* For iPhone 5 Portrait or Landscape View */
@media (device-height: 568px) 
	and (device-width: 320px) 
	and (-webkit-min-device-pixel-ratio: 2) {
	.gfg-div {
		width: 400px;
		height: 400px;
		background-color: cornflowerblue;
		color: black;
	}
}

/* For iPhone 6 and 6 plus Portrait or Landscape View */
@media (min-device-height: 667px) 
	and (min-device-width: 375px) 
	and (-webkit-min-device-pixel-ratio: 3) {
	.gfg-div {
		width: 400px;
		height: 400px;
		background-color: darkgoldenrod;
		color: black;
	}
};

x
.sc-fqkwJk{
    -webkit-mask-image: linear-gradient(black, transparent);
    mask-image: linear-gradient(black, transparent);

}

