.hooksMain > div {
    position: absolute;
    will-change: transform;
    border-radius: 50%;
    background: red;
    box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
    opacity: 0.6;
  }
  
  .hooksMain > div:nth-child(1) {
    width: 60px;
    height: 60px;
  }
  
  .hooksMain > div:nth-child(2) {
    width: 125px;
    height: 125px;
  }
  
  .hooksMain > div:nth-child(3) {
    width: 75px;
    height: 75px;
  }
  
  .hooksMain > div::after {
    content: '';
    position: absolute;
    top: 20px;
    left: 20px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.8);
  }
  
  .hooksMain > div:nth-child(2)::after {
    top: 35px;
    left: 35px;
    width: 35px;
    height: 35px;
  }
  
  .hooksMain > div:nth-child(3)::after {
    top: 25px;
    left: 25px;
    width: 25px;
    height: 25px;
  }
  
  .hooksMain {
    position: absolute;
    width: 100%;
    height: 100%;
    filter: url('#goo');
    overflow: hidden;
    background: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: default;
  }
  