html,
body {
  height: 100%;
  width: 100%;
}

#root {
  height: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;


  

/*   background:

  
radial-gradient(96.32% 150.83% at 98.4% 19.88%, #FEC957 0%, #E27520 100%)
 
  ;
  background-repeat: no-repeat; */
  
}

/* BG */

/* position: absolute;
width: 2797.6px;
height: 909.67px;

background: linear-gradient(264.7deg, #E37777 6.83%, #F2554C 42.09%, #FEC458 64.55%, #FEC957 86.42%, #DB788D 101.5%);
filter: blur(40px); */
/* body::after{
  background: linear-gradient(264.7deg, #E37777 6.83%, #F2554C 42.09%, #FEC458 64.55%, #FEC957 86.42%, #DB788D 101.5%)  50% 100% ;
filter: blur(40px);
} */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
/* 
.wave {

  animation: animateBg 14s linear infinite;
  background-image: linear-gradient(90deg, #E37777 6.83%, #F2554C 42.09%, #FEC458 64.55%, #FEC957 86.42%, #DB788D 101.5%);
  background-size: 400% 120%;
  position: fixed;
  width: 126%;
  height: 40%;

  opacity: 0.4;
  bottom: 0;
  left: 0;
  filter: blur(14px);

}

@keyframes animateBg {
  0% {
    background-position: 0% 54%
  }

  50% {
    background-position: 100% 47%
  }

  100% {
    background-position: 0% 54%
  }
}

@keyframes wave {

  0% {
    background-position: 0% 0%;
  }

  50% {
    background-position: 100% 100%;
  }

  100% {
    background-position: 0% 0%;
  }


}
 */


 .wave {
/* ы */
 /*  background-image: linear-gradient(90deg, #E37777 6.83%, #F2554C 42.09%, #FEC458 64.55%, #FEC957 86.42%, #DB788D 101.5%); */
/*   background-size: 400% 120%;
  position: fixed; */
/*   width: 126%;
  height: 40%; */

 /*  opacity: 0.4;
  bottom: 0;
  left: 0; */
/*   filter: blur(30px); */
/* filter: blur(20px);
 */}



.ocean {
  height: 50%; /* change the height of the waves here */
  width: 100%;
  position: absolute;
  bottom:  0;
  left: 0;
  right: 0;
  overflow-x: hidden;
  overflow-y: hidden !important;
}

.wave {
  background: url("./t.svg");
/*   background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 800 88.7'%3E%3Cpath d='M800 56.9c-155.5 0-204.9-50-405.5-49.9-200 0-250 49.9-394.5 49.9v31.8h800v-.2-31.6z' fill='%23003F7C'/%3E%3C/svg%3E");
 */  position: absolute;
 width: 6400px;
 height: 100%;
 bottom: -20px;
  animation: wave 20s -3s linear infinite;
  transform: translate3d(0, 0, 0);
  opacity: 0.2;
    filter: blur(2px)
/*   animation: wave 7s cubic-bezier(1, 0.45, 0.63, 1) infinite; */
}

.wave:nth-of-type(2) {
  bottom: 0;
  animation: wave 18s linear reverse infinite;
  opacity: 0.4;
  filter: blur(1px)
}

.wave:nth-of-type(3) {
  bottom: 0;
  animation: wave 20s -1s linear infinite;
  opacity: 0.2;
}

@keyframes wave {
    0% {transform: translateX(0);}
    50% {transform: translateX(-25%);}
    100% {transform: translateX(0);}
}

@-webkit-keyframes
wave {
  0% {transform: translateX(0);}
  50% {transform: translateX(-25%);}
  100% {transform: translateX(0);}
}

.animated-background {
  font-family: sans-serif;
  text-align: center;
  /* background: linear-gradient(-45deg, #310354, #9003fc, #23a6d5, #23d5ab); */
  background-size: 400% 400%;
  /* animation: gradient 15s ease infinite; */
  /* Set rules to fill background */
  min-height: 100%;

  /* Set up proportionate scaling */
  width: 100%;
  height: auto;

  /* Set up positioning */
  position: fixed;
  top: 0;
  left: 0;
  z-index: 0;
}
